<template>
<!--  <div>-->
    <!--begin::Head-->
<!--    <div class="kt-login__head">-->
<!--      <a :href="site_main_url" class="kt-login__logo">-->
<!--        <img src="/assets/media/logos/logo-black.png" />-->
<!--      </a>-->
<!--    </div>-->
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">

          <h3 class="">Reset password</h3>

          <!--          <p><a :href="site_main_url">Back to Website</a></p>-->
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <!-- <div role="alert" class="alert alert-info">
            <div class="alert-text">
              Use account <strong>admin@demo.com</strong> and password
              <strong>demo</strong> to continue.
            </div>
          </div>-->



          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Enter your email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Please enter a valid email address.</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Divider-->
          <div class="kt-login__divider">
            <div class="kt-divider"></div>
          </div>
          <!--end::Divider-->



          <!--begin::Action-->
          <div class="kt-login__actions">
             <router-link   class="btn btn-text kt-login__btn-default" :to="{ name: 'login' }">Back</router-link>

            <b-button
              :disabled="!recaptchaCheked"
              type="submit"
              id="kt_submit"
               class="btn btn-primary btn-elevate kt-login__btn-primary text-white"
            >Send instruction to email</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
        <div class="kt-login__head">
          <span class="kt-login__signup-label">{{ $t("AUTH.GENERAL.NO_ACCOUNT") }}</span>&nbsp;&nbsp;
          <router-link
              class="kt-link kt-login__signup-link"
              :to="{ name: 'register' }"
          >{{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}</router-link>
        </div>
      </div>
      <!--end::Signin-->
      <vue-recaptcha
          ref="invisibleRecaptcha"
          :sitekey="sitekey"
          :loadRecaptchaScript="true"
          size="invisible"
          @error="onError"
      ></vue-recaptcha>

      <vue-snotify></vue-snotify>
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, SET_LOGIN } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
/* import VueSocialauth from "vue-social-auth"; */
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      recaptchaCheked: true,
      // Remove this dummy login info
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ name: "dashboard" });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onError: function() {
      this.recaptchaCheked = false;
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      // dummy delay
      setTimeout(() => {
        ApiService.post("user/reset/password", {
          email: email
        })
          .then(response => {
            this.resetForm();
            this.$snotify.success(response.data.msg, {
              position: "rightTop",
              timeout: 5000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            });
          })
          .catch(({ response }) => {
            this.$snotify.error(response.data.msg, {
              position: "rightTop",
              timeout: 5000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            });
          });

        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  },
  mounted: function() {
    this.redirectIfLoggedIn();
  }
};
</script>
